import React, { Component } from "react";
import { Layout, Menu, Dropdown, Row, Col, Form, Input, message } from "antd";
import { Link, withRouter } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import "./index.less";
import { objDeepCopy, post } from "../../util";
import { editPassword } from "../../util/stan";
import Bread from "../breadcrumbNameMap";
const { Header, Content, Sider } = Layout;
const sideBarList = [
  {
    id: 166,
    name: "快捷菜单",
    icon: require("../../static/icon/sideBar/menuIcon1.svg"),
    children: [
      {
        id: 167,
        name: "Dashboard",
        path: "/admin/dashboard",
      },
    ],
    url: "",
  },
  {
    id: 87,
    name: "用户管理",
    icon: require("../../static/icon/sideBar/menuIcon2.svg"),
    children: [
      {
        id: 88,
        name: "用户列表",
        path: "/admin/user/userList",
      },
    ],
    url: "",
  },
  {
    id: 93,
    name: "商家管理",
    icon: require("../../static/icon/sideBar/menuIcon3.svg"),
    children: [
      {
        id: 94,
        name: "公海商家",
        path: "/admin/businessManagement/seasMerchants",
      },
      {
        id: 95,
        name: "商家列表",
        path: "/admin/businessManagement/merchantList",
      },
    ],
    url: "",
  },
  {
    id: 111,
    name: "订单管理",
    icon: require("../../static/icon/sideBar/menuIcon4.svg"),
    children: [
      {
        id: 112,
        name: "订单列表",
        path: "/admin/orderManagement/orderList",
      },
      {
        id: 113,
        name: "收钱码订单",
        path: "/admin/orderManagement/receivePaymentCodeOrder",
      },
      {
        id: 157,
        name: "加盟商订单",
        path: "/admin/orderManagement/allianceBusinessList",
      },
    ],
    url: "",
  },
  {
    id: 119,
    name: "财务管理",
    icon: require("../../static/icon/sideBar/menuIcon5.svg"),
    children: [
      {
        id: 120,
        name: "账户信息",
        path: "/admin/financialManagement/accountInformation",
      },
      {
        id: 121,
        name: "资金流水",
        path: "/admin/financialManagement/capital",
      },
      {
        id: 122,
        name: "转入",
        path: "/admin/financialManagement/shiftTo",
      },
      {
        id: 123,
        name: "转出",
        path: "/admin/financialManagement/shiftOut",
      },
      {
        id: 124,
        name: "转入转出记录",
        path: "/admin/financialManagement/transferRecord",
      },
      {
        id: 125,
        name: "经费控制",
        path: "/admin/financialManagement/budgetControl",
      },
      {
        id: 164,
        name: "备付金管理",
        path: "/admin/financialManagement/provisions",
      },
      {
        id: 126,
        name: "平台营收",
        path: "/admin/financialManagement/platformRevenue",
      },
      {
        id: 127,
        name: "通道费用",
        path: "/admin/financialManagement/channelCost",
      },
      {
        id: 165,
        name: "自营业务收入",
        path: "/admin/financialManagement/proprietaryIncome",
      },
    ],
    url: "",
  },
  {
    id: 1,
    name: "部门管理",
    icon: require("../../static/icon/sideBar/menuIcon7.svg"),
    children: [
      {
        id: 2,
        name: "部门列表",
        path: "/admin/divisionalManagement/departmentlist",
      },
      {
        id: 5,
        name: "成员列表",
        path: "/admin/divisionalManagement/memberList",
      },
      {
        id: 32,
        name: "绩效管理",
        path: "/admin/divisionalManagement/productivity",
      },
      {
        id: 86,
        name: "奖金结算",
        path: "/admin/divisionalManagement/bonusSettlement",
      },
    ],
    url: "",
  },
  {
    id: 180,
    name: "伙伴管理",
    icon: require("../../static/icon/sideBar/partner.svg"),
    children: [
      {
        id: 181,
        name: "合作伙伴申请",
        path: "/admin/partner",
      },
    ],
    url: "",
  },
  {
    id: 45,
    name: "平台设置",
    icon: require("../../static/icon/sideBar/menuIcon10.svg"),
    children: [
      {
        id: 79,
        name: "基础设置",
        path: "/admin/platformSetting/basic",
      },
      {
        id: 133,
        name: "验证码设置",
        path: "/admin/platformSetting/captcha",
      },
      {
        id: 135,
        name: "区域设置",
        path: "/admin/platformSetting/area",
      },
      {
        id: 134,
        name: "消息设置",
        path: "/admin/platformSetting/message",
      },
      {
        id: 77,
        name: "费率设置",
        path: "/admin/platformSetting/rateSet",
      },
      {
        id: 82,
        name: "日志管理",
        path: "/admin/platformSetting/log",
      },
      {
        id: 46,
        name: "功能配置",
        path: "/admin/platformSetting/functionAllocation",
      },
      {
        id: 136,
        name: "营销配置",
        path: "/admin/platformSetting/marketing",
      },
      {
        id: 51,
        name: "版本管理",
        path: "/admin/platformSetting/versionsList",
      },
      {
        id: 159,
        name: "资质协议",
        path: "/admin/platformSetting/aptitudes",
      },
    ],
    url: "",
  },
  {
    id: 62,
    name: "营销管理",
    icon: require("../../static/icon/sideBar/menuIcon9.svg"),
    children: [
      {
        id: 63,
        name: "首页轮播",
        path: "/admin/marketingManagement/homePage",
      },
      {
        id: 64,
        name: "类目导航",
        path: "/admin/marketingManagement/categoryNavigation",
      },
      {
        id: 178,
        name: "广告位",
        path: "/admin/marketingManagement/advertisingPosition",
      },
      {
        name: "广告位",
        path: "/admin/marketingManagement/advertisingPosition/info",
      },
      {
        id: 179,
        name: "类目导航",
        path: "/admin/marketingManagement/categoryNavigationNew",
      },
      {
        id: 188,
        name: "爆品引流方案",
        path: "/admin/marketingManagement/explosiveGoodsSolution",
      },
      {
        id: 206,
        name: "推广位管理",
        path: "/admin/marketingManagement/promotionActivity",
      },
      {
        id: 207,
        name: "推广商品管理",
        path: "/admin/marketingManagement/promotionGoods",
      },
      {
        name: "爆品引流方案",
        path: "/admin/marketingManagement/explosiveGoodsSolution/edit",
      },
      {
        id: 208,
        name: "链爆同城",
        path: "/admin/marketingManagement/linkCity",
      },
    ],
    url: "",
  },
  {
    id: 85,
    name: "内容管理",
    icon: require("../../static/icon/sideBar/menuIcon8.svg"),
    children: [
      {
        id: 57,
        name: "帮助中心",
        path: "/admin/contentManagement/helpCenter",
      },
    ],
    url: "",
  },
  {
    id: 140,
    name: "商品管理",
    icon: require("../../static/icon/sideBar/menuIcon11.svg"),
    children: [
      {
        id: 141,
        name: "商品分类",
        path: "/admin/commodityManagement/classify",
      },
      {
        id: 142,
        name: "规格列表",
        path: "/admin/commodityManagement/specification",
      },
      {
        id: 143,
        name: "属性列表",
        path: "/admin/commodityManagement/property",
      },
      {
        id: 189,
        name: "商品档案",
        path: "/admin/commodityManagement/goodsRecord",
      },
      {
        id: 202,
        name: "线下经营品类",
        path: "/admin/commodityManagement/categoryOffline",
      },
      {
        name: "商品档案",
        path: "/admin/commodityManagement/goodsRecord/info",
      },
    ],
    url: "",
  },
  {
    id: 160,
    name: "商家运营",
    icon: require("../../static/icon/sideBar/menuIcon12.svg"),
    children: [],
    url: "",
  },
  {
    id: 162,
    name: "用户运营",
    icon: require("../../static/icon/sideBar/menuIcon13.svg"),
    children: [],
    url: "",
  },
  {
    id: 163,
    name: "市场业务",
    icon: require("../../static/icon/sideBar/menuIcon14.svg"),
    children: [],
    url: "",
  },
];
class Frame extends Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      showRightAside: false,
      sideBarRight: [],
      sideBarRightId: "",
      rightActiveIndex: "",
      leftActiveIndex: 0,
      changeColorId: "",
      sideBarLeft: [],
      dialog: false,
    };
  }
  componentWillMount() {
    const leftActiveIndex = sessionStorage.getItem("leftActiveIndex");
    const rightActiveIndex = sessionStorage.getItem("rightActiveIndex");
    const myRole = JSON.parse(sessionStorage.getItem("myRole"))
      ? JSON.parse(sessionStorage.getItem("myRole"))
      : [];
    const sideBarLeft = [];
    sideBarList.forEach((v) => {
      myRole.forEach((item) => {
        if (item.id === v.id) {
          v.url = item.url;
          const content = objDeepCopy(v);
          sideBarLeft.push(content);
          sideBarLeft.forEach((items, index) => {
            sideBarLeft[index].children = [];
          });
        }
      });
    });
    sideBarList.forEach((v) => {
      myRole.forEach((item) => {
        if (item.id === v.id) {
          v.children.forEach((it) => {
            item.children.forEach((myIt, myIn) => {
              if (it.id === myIt.id) {
                sideBarLeft.filter((em) => {
                  if (em.id === v.id) {
                    em.children.push(it);
                  }
                  return v.id;
                });
              }
            });
          });
        }
      });
    });
    sideBarLeft.unshift(sideBarList[0]);
    this.setState({
      sideBarLeft,
      rightActiveIndex: rightActiveIndex
        ? Number(rightActiveIndex)
        : sideBarList[0].children[0].id,
      leftActiveIndex: leftActiveIndex ? Number(leftActiveIndex) : 0,
    });
  }
  componentDidMount() {
    this.props.history.listen((route) => {
      console.log(this.props);
    });
  }
  onReset = () => {
    this.formRef.current.resetFields();
  };
  selectRightBar = (child, index) => {
    sessionStorage.removeItem("postData");
    sessionStorage.removeItem("searchFrom");
    this.setState({
      rightActiveIndex: child.id,
      leftActiveIndex: index,
    });
    sessionStorage.setItem("leftActiveIndex", index);
    sessionStorage.setItem("rightActiveIndex", child.id);
  };
  showAside = (item) => {
    this.setState({
      sideBarRight: item.children,
      sideBarRightId: item.id,
    });
  };
  hideAside = () => {
    this.setState({
      sideBarRightId: "",
    });
  };
  changeColor = (item) => {
    this.setState({
      changeColorId: item.id,
    });
  };
  noChangeColor = () => {
    this.setState({
      changeColorId: "",
    });
  };
  //修改密码
  changePassword = () => {
    this.setState({
      dialog: true,
    });
  };
  //退出登录
  authLogout = () => {
    sessionStorage.clear();
    window.location.reload();
  };
  cancel = () => {
    this.formRef.current.resetFields();
    this.setState({
      dialog: false,
    });
  };
  sure = async () => {
    try {
      const values = await this.formRef.current.validateFields();
      post(editPassword, values).then((res) => {
        if (res.status_code === 0) {
          this.setState({
            dialog: false,
          });
          message.success("修改成功");
        } else {
          message.error(res.message);
        }
      });
    } catch (errorInfo) {}
  };
  render() {
    const nickname = sessionStorage.getItem("nickname");
    const menu = (
      <Menu>
        <Menu.Item key="0">
          <div onClick={this.changePassword}>修改密码</div>
        </Menu.Item>
        <Menu.Item key="1">
          <div onClick={this.authLogout}>退出登录</div>
        </Menu.Item>
      </Menu>
    );
    const formItemLayout = {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 18,
      },
    };
    return (
      <Layout>
        <Header
          style={{
            width: "100%",
            background: "#313036",
            padding: "0 30px",
            position: "fixed",
            left: "0",
            top: "0",
            zIndex: "999",
          }}
          className="header"
        >
          <div className="flex_between">
            <img
              style={{ width: "125px", height: "31px" }}
              src={require("../../static/icon/sideBar/logo.svg")}
              alt=""
            />
            <div className="top flex_end">
              <div className="list flex_center">
                <img
                  src={require("../../static/icon/topBar/tobBarIcon1.svg")}
                  alt=""
                />
                消息
              </div>
              <div className="list flex_center">
                <img
                  src={require("../../static/icon/topBar/tobBarIcon2.svg")}
                  alt=""
                />
                锁屏
              </div>
              <div className="list flex_center">
                <img
                  src={require("../../static/icon/topBar/tobBarIcon3.svg")}
                  alt=""
                />
                管理
              </div>
              <Dropdown overlay={menu} trigger={["click"]}>
                <span
                  style={{ color: "#fff", cursor: "pointer" }}
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  {nickname}
                  <DownOutlined />
                </span>
              </Dropdown>
            </div>
          </div>
        </Header>
        <Layout style={{ padding: "20px" }}>
          <Sider width={200} className="aside">
            {this.state.sideBarLeft.map((item, index) => {
              return (
                <div key={item.name} style={{ fontSize: "14px" }}>
                  {!item.url ? (
                    <div
                      style={
                        (this.state.sideBarRightId === item.id
                          ? { background: "rgba(250,80,45,0.05)" }
                          : {},
                        index === 0 ? { marginTop: "0" } : {})
                      }
                      onMouseEnter={this.showAside.bind(this, item)}
                      onMouseLeave={this.hideAside}
                      className={`flex_between leftAside ${
                        index === this.state.leftActiveIndex
                          ? "activeTitle"
                          : ""
                      }`}
                    >
                      <div className="flex_start">
                        <img
                          style={{ marginRight: "6px" }}
                          alt=""
                          src={item.icon}
                        />
                        <div style={{ color: "#1C1C1C", fontWeight: "600" }}>
                          {item.name}
                        </div>
                      </div>
                      {this.state.sideBarRightId === item.id ? (
                        <div className="rightAside">
                          <div>
                            {this.state.sideBarRight.map((child) => {
                              return (
                                <Link
                                  to={child.path}
                                  key={child.path}
                                  style={{
                                    display: "block",
                                    margin: "10px 0",
                                    cursor: "pointer",
                                  }}
                                >
                                  <div
                                    onClick={this.selectRightBar.bind(
                                      this,
                                      child,
                                      index
                                    )}
                                    style={{
                                      textAlign: "left",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {child.name}
                                  </div>
                                </Link>
                              );
                            })}
                          </div>
                        </div>
                      ) : null}
                      {this.state.sideBarRightId === item.id ? (
                        <img
                          style={{ width: "4px", height: "8px" }}
                          alt=""
                          src={require("../../static/icon/business/go.svg")}
                        />
                      ) : null}
                    </div>
                  ) : (
                    <a
                      style={{
                        marginTop: "30px",
                        height: "30px",
                        textAlign: "left",
                        display: "block",
                      }}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={item.url}
                    >
                      <div className="flex_start" style={{ padding: "0 16px" }}>
                        <img
                          style={{ marginRight: "6px" }}
                          alt=""
                          src={item.icon}
                        />
                        <div style={{ color: "#1C1C1C", fontWeight: "600" }}>
                          {item.name}
                        </div>
                      </div>
                    </a>
                  )}
                  <div
                    className="flex_start_wrap"
                    style={{
                      textAlign: "left",
                      padding: "0 16px",
                      marginRight: "10px",
                    }}
                  >
                    {item.children.map((child) => {
                      return (
                        <Link
                          to={child.path}
                          key={child.id}
                          style={{
                            marginTop: "10px",
                            minWidth: "50%",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={
                              this.state.changeColorId === child.id ||
                              this.state.rightActiveIndex === child.id
                                ? { color: "#fa502d" }
                                : { color: "#3e3e3e" }
                            }
                            onClick={this.selectRightBar.bind(
                              this,
                              child,
                              index
                            )}
                            onMouseEnter={this.changeColor.bind(this, child)}
                            onMouseLeave={this.noChangeColor}
                          >
                            {child.name}
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </Sider>
          <Layout style={{ marginTop: "64px", minWidth: "1200px" }}>
            <Bread />
            <Content
              className="site-layout-background"
              style={{
                paddingTop: 0,
                margin: 0,
                minHeight: 280,
              }}
            >
              {this.props.children}
            </Content>
          </Layout>
        </Layout>
        {this.state.dialog ? (
          <div className="mask">
            <div className="maskContent" style={{ width: "600px" }}>
              <Row className="title">
                <Col span={12} style={{ textAlign: "left" }}>
                  <div>修改密码</div>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <img
                    className="closeImg"
                    alt=""
                    onClick={this.cancel}
                    src={require("../../static/image/close.svg")}
                  />
                </Col>
              </Row>
              <div className="maskMain" style={{ height: "200px" }}>
                <Form
                  {...formItemLayout}
                  scrollToFirstError
                  ref={this.formRef}
                  name="control-ref"
                  onFinish={this.onFinish}
                >
                  <Form.Item
                    name="old_password"
                    label="原密码："
                    rules={[{ required: true, message: "原密码不能为空" }]}
                  >
                    <Input placeholder="请输入" size="large" type="password" />
                  </Form.Item>
                  <Form.Item
                    name="new_password"
                    label="新密码："
                    rules={[{ required: true, message: "新密码不能为空" }]}
                  >
                    <Input type="password" size="large" placeholder="请输入" />
                  </Form.Item>
                  <Form.Item
                    name="new_two_password"
                    label="再次输入："
                    dependencies={["new_two_password"]}
                    rules={[
                      { required: true, message: "请再次输入密码" },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            !value ||
                            getFieldValue("new_password") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject("请保证两次输入密码一致");
                        },
                      }),
                    ]}
                  >
                    <Input placeholder="请输入" size="large" type="password" />
                  </Form.Item>
                </Form>
              </div>
              <div className="maskBottom">
                <div
                  style={{ marginRight: "10px" }}
                  onClick={this.cancel}
                  className="cancel"
                >
                  取消
                </div>
                <div className="sure" onClick={this.sure}>
                  确定
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Layout>
    );
  }
}
export default withRouter(Frame);
