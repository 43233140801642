import React from 'react'
import { render } from 'react-dom'
import zhCN from 'antd/es/locale/zh_CN'
import { ConfigProvider } from 'antd'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import App from './App'
import './index.less'
import { mainRouter } from './routes'
import moment from 'moment'
import 'moment/locale/zh-cn'
import "./icons";
moment.locale('zh-cn')
render(
    <Router>
        <ConfigProvider locale={zhCN}>
            <Switch>
                <Route path="/admin" render={(routerProps) => {
                    return <App {...routerProps} />
                }} />
                {
                    mainRouter.map(route => {
                        return <Route key={route.pathname} path={route.pathname} component={route.component} />
                    })
                }
                <Redirect to="/admin/dashboard" exact from="/"/>
            </Switch>
        </ConfigProvider>
    </Router>,
    document.querySelector('#root')
)