import {
  Dashboard,
  Login,
  NotFound,
  UserList,
  UserDetail,
  SeasMerchants,
  MerchantList,
  MerchantDetail,
  OrderList,
  OrderDetail,
  ReceivePaymentCodeOrder,
  CodeOrderDetail,
  AllianceBusinessList,
  AllianceBusinessDetail,
  AccountInformation,
  Capital,
  ShiftTo,
  ShiftOut,
  TransferRecord,
  BudgetControl,
  Provisions,
  PlatformRevenue,
  ChannelCost,
  ProprietaryIncome,
  Departmentlist,
  VersionsDetail,
  VersionsAmend,
  AptitudesDetail,
  MemberList,
  Productivity,
  BonusSettlement,
  Basic,
  Captcha,
  Area,
  Message,
  RateSet,
  Log,
  FunctionAllocation,
  FunctionAllocation2,
  FunctionAllocation3,
  Marketing,
  VersionsList,
  VersionsRelease,
  Aptitudes,
  HomePage,
  CategoryNavigation,
  HelpCenter,
  Classify,
  Specification,
  Property,
  Departmentlimit,
  Subdivision,
  Memberlimit,
  MemberAdd,
  MemberEdit,
  AdvAdd,
  AdvEdit,
  HelpAdd,
  HelpEdit,
  SpecificationAdd,
  classSpecifications,
  classifyProperty,
  ClassifyTwo,
  ClassifyThree,
  ClassifyFour,
  SpecificationAmend,
  PropertyAdd,
  PropertyAmend,
  NewSize,
  NewColor,
  Partner,
  PartnerStep,
  PartnerStepEdit,
  GoodsRecord,
  GoodsRecordEdit,
  ExplosiveGoodsSolution,
  ExplosiveGoodsSolutionEdit,
  PromotionActivity,
  PromotionGoods,
  LinkCity,
  SelectGoods,
  advertisingPositionList,
  advertisingPositionInfo,
  categoryNavigationNew,
  CategoryOffline,
  CategoryOfflineTwo,
  CategoryOfflineThree,
  CategoryOfflineFour,
  CategoryOfflineProperty,
} from "../views";
export const mainRouter = [
  {
    pathname: "/login",
    component: Login,
  },
  {
    pathname: "/404",
    component: NotFound,
  },
];

export const adminRouter = [
  {
    pathname: "/admin/dashboard",
    component: Dashboard,
    name: "Dashboard",
  },
  {
    pathname: "/admin/user/userList",
    component: UserList,
    exact: true,
    name: "用户列表",
    params: {
      id: 88,
      premission: ["89", "90", "91", "92"],
    },
  },
  {
    pathname: "/admin/user/userList/userDetail",
    component: UserDetail,
    name: "用户详情",
  },
  {
    pathname: "/admin/businessManagement/seasMerchants",
    component: SeasMerchants,
    exact: true,
    name: "公海商家",
    params: {
      id: 94,
      premission: ["96", "97", "98", "99", "100", "101"],
    },
  },
  {
    pathname: "/admin/businessManagement/merchantList",
    component: MerchantList,
    name: "商家列表",
    params: {
      id: 95,
      premission: [
        "102",
        "103",
        "104",
        "105",
        "106",
        "107",
        "108",
        "109",
        "110",
      ],
    },
  },
  {
    pathname: "/admin/businessManagement/merchantDetail",
    component: MerchantDetail,
    name: "商家详情",
  },
  {
    pathname: "/admin/orderManagement/orderList",
    component: OrderList,
    exact: true,
    name: "订单列表",
    params: {
      id: 112,
      premission: ["114"],
    },
  },
  {
    pathname: "/admin/orderManagement/orderList/orderDetail",
    component: OrderDetail,
    name: "订单详情",
  },
  {
    pathname: "/admin/orderManagement/receivePaymentCodeOrder",
    exact: true,
    component: ReceivePaymentCodeOrder,
    name: "收钱码订单",
    params: {
      id: 113,
      premission: ["115", "116", "117", "118"],
    },
  },
  {
    pathname: "/admin/orderManagement/receivePaymentCodeOrder/detail",
    component: CodeOrderDetail,
    name: "收钱码订单详情",
  },
  {
    pathname: "/admin/orderManagement/allianceBusinessList",
    exact: true,
    component: AllianceBusinessList,
    name: "加盟商订单",
    params: {
      id: 157,
      premission: ["158"],
    },
  },
  {
    pathname: "/admin/orderManagement/allianceBusinessList/detail",
    component: AllianceBusinessDetail,
    name: "订单详情",
  },
  {
    pathname: "/admin/financialManagement/accountInformation",
    component: AccountInformation,
    name: "账户信息",
  },
  {
    pathname: "/admin/financialManagement/capital",
    component: Capital,
    name: "资金流水",
  },
  {
    pathname: "/admin/financialManagement/shiftTo",
    component: ShiftTo,
    name: "转入",
  },
  {
    pathname: "/admin/financialManagement/shiftOut",
    component: ShiftOut,
    name: "转出",
  },
  {
    pathname: "/admin/financialManagement/transferRecord",
    component: TransferRecord,
    name: "转入转出记录",
  },
  {
    pathname: "/admin/financialManagement/budgetControl",
    component: BudgetControl,
    name: "经费控制",
    params: {
      id: 125,
      premission: ["130"],
    },
  },
  {
    pathname: "/admin/financialManagement/provisions",
    component: Provisions,
    name: "备付金管理",
  },
  {
    pathname: "/admin/financialManagement/platformRevenue",
    component: PlatformRevenue,
    name: "平台营收",
    params: {
      id: 126,
      premission: ["129"],
    },
  },
  {
    pathname: "/admin/financialManagement/channelCost",
    component: ChannelCost,
    name: "通道费用",
    params: {
      id: 127,
      premission: ["128"],
    },
  },
  {
    pathname: "/admin/financialManagement/proprietaryIncome",
    component: ProprietaryIncome,
    name: "自营业务收入",
  },
  {
    pathname: "/admin/divisionalManagement/departmentlist",
    component: Departmentlist,
    exact: true,
    name: "部门列表",
    params: {
      id: 2,
      premission: ["10", "11", "23", "24", "25", "26", "27", "42", "43", "44"],
    },
  },
  {
    pathname: "/admin/divisionalManagement/departmentlist/limit",
    component: Departmentlimit,
    name: "权限设置",
  },
  {
    pathname: "/admin/divisionalManagement/departmentlist/subdivision",
    component: Subdivision,
    name: "子部门",
  },
  {
    pathname: "/admin/divisionalManagement/memberList",
    component: MemberList,
    exact: true,
    name: "成员列表",
    params: {
      id: 5,
      premission: ["6", "7", "8", "28", "29", "30", "31"],
    },
  },
  {
    pathname: "/admin/divisionalManagement/memberList/limit",
    component: Memberlimit,
    name: "权限设置",
  },
  {
    pathname: "/admin/divisionalManagement/memberList/add",
    component: MemberAdd,
    name: "添加成员",
  },
  {
    pathname: "/admin/divisionalManagement/memberList/edit",
    component: MemberEdit,
    name: "编辑成员",
  },
  {
    pathname: "/admin/divisionalManagement/productivity",
    component: Productivity,
    name: "绩效管理",
    params: {
      id: 5,
      premission: ["33", "34", "35", "36", "37", "38", "40", "41"],
    },
  },
  {
    pathname: "/admin/divisionalManagement/bonusSettlement",
    component: BonusSettlement,
    name: "奖金结算",
    params: {
      id: 86,
      premission: ["39"],
    },
  },
  {
    exact: true,
    pathname: "/admin/partner",
    component: Partner,
    name: "合作伙伴申请",
  },
  {
    exact: true,
    pathname: "/admin/partner/step",
    component: PartnerStep,
    name: "",
  },
  {
    exact: true,
    pathname: "/admin/partner/fill",
    component: PartnerStepEdit,
    name: "资料填写",
  },
  {
    pathname: "/admin/platformSetting/basic",
    component: Basic,
    name: "基础设置",
    params: {
      id: 79,
      premission: ["80", "81"],
    },
  },
  {
    pathname: "/admin/platformSetting/captcha",
    component: Captcha,
    name: "验证码设置",
  },
  {
    pathname: "/admin/platformSetting/area",
    component: Area,
    name: "区域设置",
    params: {
      id: 135,
      premission: ["47", "48", "49", "50"],
    },
  },
  {
    pathname: "/admin/platformSetting/message",
    component: Message,
    name: "消息设置",
  },
  {
    pathname: "/admin/platformSetting/rateSet",
    component: RateSet,
    name: "费率设置",
    params: {
      id: 77,
      premission: ["78", "132"],
    },
  },
  {
    pathname: "/admin/platformSetting/log",
    component: Log,
    name: "日志管理",
    params: {
      id: 82,
      premission: ["83", "84"],
    },
  },
  {
    pathname: "/admin/platformSetting/functionAllocation",
    component: FunctionAllocation,
    name: "功能配置",
    params: {
      id: 46,
      premission: ["47", "48", "49", "50"],
    },
  },
  {
    pathname: "/admin/platformSetting/functionAllocation2",
    component: FunctionAllocation2,
    name: "功能配置",
    params: {
      id: 46,
      premission: ["47", "48", "49", "50"],
    },
  },
  {
    pathname: "/admin/platformSetting/functionAllocation3",
    component: FunctionAllocation3,
    name: "功能配置",
    params: {
      id: 46,
      premission: ["47", "48", "49", "50"],
    },
  },
  {
    pathname: "/admin/platformSetting/marketing",
    component: Marketing,
    name: "营销配置",
  },
  {
    pathname: "/admin/platformSetting/versionsList",
    component: VersionsList,
    name: "版本管理",
    params: {
      id: 51,
      premission: ["52", "53", "54", "55"],
    },
  },
  {
    pathname: "/admin/platformSetting/versionsRelease",
    component: VersionsRelease,
    name: "发布新版",
  },
  {
    pathname: "/admin/platformSetting/versionsDetail",
    component: VersionsDetail,
    name: "版本详情",
  },
  {
    pathname: "/admin/platformSetting/versionsAmend",
    component: VersionsAmend,
    name: "修改",
  },
  {
    pathname: "/admin/platformSetting/aptitudes",
    component: Aptitudes,
    name: "资质协议",
  },
  {
    pathname: "/admin/platformSetting/aptitudesDetail",
    component: AptitudesDetail,
    name: "资质协议",
  },
  {
    pathname: "/admin/marketingManagement/homePage",
    component: HomePage,
    name: "首页轮播",
    exact: true,
    params: {
      id: 63,
      premission: ["65", "66", "67", "68", "69"],
    },
  },
  {
    pathname: "/admin/marketingManagement/homePage/add",
    component: AdvAdd,
    name: "发布广告",
  },
  {
    pathname: "/admin/marketingManagement/homePage/edit",
    component: AdvEdit,
    name: "编辑广告",
  },
  {
    pathname: "/admin/marketingManagement/categoryNavigation",
    component: CategoryNavigation,
    name: "类目导航",
    params: {
      id: 64,
      premission: ["70", "71", "72", "73"],
    },
  },
  {
    exact: true,
    pathname: "/admin/marketingManagement/advertisingPosition",
    component: advertisingPositionList,
    name: "广告位",
  },
  {
    pathname: "/admin/marketingManagement/advertisingPosition/info",
    component: advertisingPositionInfo,
    name: "",
  },
  {
    exact: true,
    pathname: "/admin/marketingManagement/categoryNavigationNew",
    component: categoryNavigationNew,
    name: "类目导航",
  },
  {
    exact: true,
    pathname: "/admin/marketingManagement/explosiveGoodsSolution",
    component: ExplosiveGoodsSolution,
    name: "爆品引流方案",
  },
  {
    pathname: "/admin/marketingManagement/explosiveGoodsSolution/edit",
    component: ExplosiveGoodsSolutionEdit,
    name: "",
  },
  {
    exact: true,
    pathname: "/admin/marketingManagement/promotionActivity",
    component: PromotionActivity,
    name: "推广位管理",
  },
  {
    exact: true,
    pathname: "/admin/marketingManagement/promotionGoods",
    component: PromotionGoods,
    name: "推广商品管理",
  },
  {
    exact: true,
    pathname: "/admin/marketingManagement/linkCity",
    component: LinkCity,
    name: "链爆同城",
  },
  {
    exact: true,
    pathname: "/admin/marketingManagement/promotionGoods/selectGoods",
    component: SelectGoods,
    name: "挑选商品",
  },
  {
    pathname: "/admin/contentManagement/helpCenter",
    component: HelpCenter,
    name: "帮助中心",
    exact: true,
    params: {
      id: 57,
      premission: ["58", "49", "60", "61"],
    },
  },
  {
    pathname: "/admin/contentManagement/helpCenter/add",
    component: HelpAdd,
    name: "发布帮助",
  },
  {
    pathname: "/admin/contentManagement/helpCenter/edit",
    component: HelpEdit,
    name: "编辑帮助",
  },
  {
    pathname: "/admin/commodityManagement/classify",
    component: Classify,
    name: "商品分类",
    exact: true,
    params: {
      id: 141,
      premission: ["144", "145", "146", "155", "156"],
    },
  },
  {
    pathname: "/admin/commodityManagement/classifyTwo",
    component: ClassifyTwo,
    name: "商品分类",
  },
  {
    pathname: "/admin/commodityManagement/classifyThree",
    component: ClassifyThree,
    name: "商品分类",
  },
  {
    pathname: "/admin/commodityManagement/classifyFour",
    component: ClassifyFour,
    name: "商品分类",
  },
  {
    pathname: "/admin/commodityManagement/classify/spec",
    component: classSpecifications,
    name: "规格管理",
  },
  {
    pathname: "/admin/commodityManagement/classify/property",
    component: classifyProperty,
    name: "属性管理",
  },
  {
    pathname: "/admin/commodityManagement/categoryOffline",
    component: CategoryOffline,
    name: "线下经营品类",
    exact: true,
    params: {
      id: 202,
      premission: ["202", "203", "204", "205"],
    },
  },
  {
    pathname: "/admin/commodityManagement/categoryOfflineTwo",
    component: CategoryOfflineTwo,
    name: "线下经营品类",
  },
  {
    pathname: "/admin/commodityManagement/categoryOfflineThree",
    component: CategoryOfflineThree,
    name: "线下经营品类",
  },
  {
    pathname: "/admin/commodityManagement/categoryOfflineFour",
    component: CategoryOfflineFour,
    name: "线下经营品类",
  },
  {
    pathname: "/admin/commodityManagement/categoryOffline/spec",
    component: classSpecifications,
    name: "规格管理",
  },
  {
    pathname: "/admin/commodityManagement/categoryOffline/property",
    component: CategoryOfflineProperty,
    name: "属性管理",
  },
  {
    pathname: "/admin/commodityManagement/specification",
    component: Specification,
    name: "规格列表",
    params: {
      id: 142,
      premission: ["147", "148", "149", "150"],
    },
  },
  {
    pathname: "/admin/commodityManagement/specificationAdd",
    component: SpecificationAdd,
    name: "添加规格",
  },
  {
    pathname: "/admin/commodityManagement/specificationAmend",
    component: SpecificationAmend,
    name: "编辑规格",
  },
  {
    pathname: "/admin/commodityManagement/property",
    component: Property,
    name: "属性列表",
    params: {
      id: 143,
      premission: ["151", "152", "153", "154"],
    },
  },
  {
    pathname: "/admin/commodityManagement/propertyAdd",
    component: PropertyAdd,
    name: "添加属性",
  },
  {
    pathname: "/admin/commodityManagement/propertyAmend",
    component: PropertyAmend,
    name: "编辑属性",
  },
  {
    pathname: "/admin/commodityManagement/newSize",
    component: NewSize,
    name: "规格尺码",
  },
  {
    pathname: "/admin/commodityManagement/newColor",
    component: NewColor,
    name: "规格颜色",
  },
  {
    exact: true,
    pathname: "/admin/commodityManagement/goodsRecord",
    component: GoodsRecord,
    name: "商品档案",
  },
  {
    exact: true,
    pathname: "/admin/commodityManagement/goodsRecord/info",
    component: GoodsRecordEdit,
    name: "商品档案",
  },
];
