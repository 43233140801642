import React, { Component } from 'react'
import { Input, message, Col } from 'antd'
import { getCategoryList, addShopCategory, delShopCategory } from '../../util/stan'
import { post } from '../../util'
export default class TypeLi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showType: '2',
            one: [],
            two: [],
            three: [],
            four: [],
            five: [],
            addOne: false,
            addValue1: '',

            addTwo: false,
            onePid: '',
            addValue2: '',

            addThree: false,
            twoPid: '',
            addValue3: '',

            addfour: false,
            threePid: '',
            addValue4: '',

            addfive: false,
            fourPid: '',
            addValue5: '',

            oneIndex: -1,
            twoIndex: -1,
            threeIndex: -1,
            fourIndex: -1,
            fiveIndex: -1,
        }
    }
    componentWillMount(){
        post(getCategoryList, {id:0}).then(res=>{
            if (res.status_code === 0) {
                this.setState({
                    one: res.data.list
                })
                this.selectOne(res.data.list[0], 0);
            } else {
                message.error(res.message)
            }
        })
    }
    switchShow = (showType) => {
        this.props.changeType(showType)
        post(getCategoryList, {id:0}).then(res=>{
            if (res.status_code === 0) {
                this.setState({
                    one: res.data.list
                })
                this.selectOne(res.data.list[0], 0);
            } else {
                message.error(res.message)
            }
        })
    }
    selectOne = (item, index) => {
        if (this.state.oneIndex !== index) {
            post(getCategoryList, {id:item.id}).then(res=>{
                if (res.status_code === 0) {
                    this.setState({
                        two: res.data.list,
                        three: [],
                        four: [],
                        five: [],
                        twoIndex: -1,
                        threeIndex: -1,
                        fourIndex: -1,
                        fiveIndex: -1,
                        addTwo: false,
                        addThree: false,
                        addfour: false,
                        addfive: false,
                        oneIndex: index,
                        onePid: item.id
                    })
                } else {
                    message.error(res.message)
                }
            })
        }
    }
    selectTwo = (item, index) => {
        if (this.state.twoIndex !== index) {
            post(getCategoryList, {id:item.id}).then(res=>{
                if (res.status_code === 0) {
                    this.setState({
                        three: res.data.list,
                        four: [],
                        five: [],
                        threeIndex: -1,
                        fourIndex: -1,
                        fiveIndex: -1,
                        addThree: false,
                        addfour: false,
                        addfive: false,
                        twoIndex: index,
                        twoPid: item.id
                    })
                } else {
                    message.error(res.message)
                }
            })
        }
    }
    selectThree = (item, index) => {
        if (this.state.threeIndex !== index) {
            post(getCategoryList, {id:item.id}).then(res=>{
                if (res.status_code === 0) {
                    this.setState({
                        four: res.data.list,
                        five: [],
                        fourIndex: -1,
                        fiveIndex: -1,
                        addfour: false,
                        addfive: false,
                        threeIndex: index,
                        threePid: item.id
                    })
                } else {
                    message.error(res.message)
                }
            })
        }
    }
    selectFour = (item, index) => {
        if (this.state.fourIndex !== index) {
            post(getCategoryList, {id:item.id}).then(res=>{
                if (res.status_code === 0) {
                    this.setState({
                        five: res.data.list,
                        fiveIndex: -1,
                        addfive: false,
                        fourIndex: index,
                        fourPid: item.id
                    })
                } else {
                    message.error(res.message)
                }
            })
        }
    }
    selectfive = (item,index) => {
        this.setState({
            fiveIndex: index
        })
    }
    deletes = (e, pid, num) => {
        post(delShopCategory, {id: e}).then(res=>{
            if (res.status_code === 0) {
                message.success('删除成功')
                post(getCategoryList, {id:pid}).then(res=>{
                    if (res.status_code === 0) {
                        if (num === 2) {
                            this.setState({
                                two: res.data.list,
                                three:[],
                                four:[],
                                five:[]
                            })
                        } else if (num === 3) {
                            this.setState({
                                three: res.data.list,
                                four:[],
                                five:[]
                            })
                        } else if (num === 4) {
                            this.setState({
                                four: res.data.list,
                                five:[]
                            })
                        } else if (num === 5) {
                            this.setState({
                                five: res.data.list
                            })
                        }
                    } else {
                        message.error(res.message)
                    }
                })
            } else {
                message.error(res.message)
            }
        })
    }
    addChange2 = (e) => {
        this.setState({
            addValue2: e.target.value
        })
    }
    addChange3 = (e) => {
        this.setState({
            addValue3: e.target.value
        })
    }
    addChange4 = (e) => {
        this.setState({
            addValue4: e.target.value
        })
    }
    addChange5 = (e) => {
        this.setState({
            addValue5: e.target.value
        })
    }
    addShopCategory2 = () => {
        var postData = {
            pid: this.state.onePid,
            category_name: this.state.addValue2,
            level:'1'
        }
        post(addShopCategory, postData).then(res=>{
            if (res.status_code === 0) {
                message.success('添加成功')
                post(getCategoryList, {id:this.state.onePid}).then(res=>{
                    if (res.status_code === 0) {
                        this.setState({
                            two: res.data.list,
                            addValue2: '',
                            addTwo: false,
                        })
                    } else {
                        message.error(res.message)
                    }
                })
            } else {
                message.error(res.message)
            }
        })
    }
    addShopCategory3 = () => {
        var postData = {
            pid: this.state.twoPid,
            category_name: this.state.addValue3,
            level:'2'
        }
        post(addShopCategory, postData).then(res=>{
            if (res.status_code === 0) {
                message.success('添加成功')
                post(getCategoryList, {id:this.state.twoPid}).then(res=>{
                    if (res.status_code === 0) {
                        this.setState({
                            three: res.data.list,
                            addValue3: '',
                            addThree: false,
                        })
                    } else {
                        message.error(res.message)
                    }
                })
            } else {
                message.error(res.message)
            }
        })
    }
    addShopCategory4 = () => {
        var postData = {
            pid: this.state.threePid,
            category_name: this.state.addValue4,
            level:'3'
        }
        post(addShopCategory, postData).then(res=>{
            if (res.status_code === 0) {
                message.success('添加成功')
                post(getCategoryList, {id:this.state.threePid}).then(res=>{
                    if (res.status_code === 0) {
                        this.setState({
                            four: res.data.list,
                            addValue4: '',
                            addfour: false,
                        })
                    } else {
                        message.error(res.message)
                    }
                })
            } else {
                message.error(res.message)
            }
        })
    }
    addShopCategory5 = () => {
        var postData = {
            pid: this.state.fourPid,
            category_name: this.state.addValue5,
            level:'4'
        }
        post(addShopCategory, postData).then(res=>{
            if (res.status_code === 0) {
                message.success('添加成功')
                post(getCategoryList, {id:this.state.fourPid}).then(res=>{
                    if (res.status_code === 0) {
                        this.setState({
                            five: res.data.list,
                            addValue5: '',
                            addfive: false,
                        })
                    } else {
                        message.error(res.message)
                    }
                })
            } else {
                message.error(res.message)
            }
        })
    }
    openAdd2 = () => {
        this.setState({
            addTwo:true,
            addThree:false,
            addfour:false,
            addfive:false,
        })
    }
    openAdd3 = () => {
        if (this.state.twoIndex === -1) {
            message.error('请先选择一级分类')
        } else {
            this.setState({
                addTwo:false,
                addThree:true,
                addfour:false,
                addfive:false,
            })
        }
    }
    openAdd4 = () => {
        if (this.state.threeIndex === -1) {
            message.error('请先选择二级分类')
        } else {
            this.setState({
                addTwo:false,
                addThree:false,
                addfour:true,
                addfive:false,
            })
        }
    }
    openAdd5 = () => {
        if (this.state.threeIndex === -1) {
            message.error('请先选择三级分类')
        } else {
            this.setState({
                addTwo:false,
                addThree:false,
                addfour:false,
                addfive:true,
            })
        }
    }
    cancelTwo = () => {
        this.setState({
            addTwo:false
        })
    }
    cancelThree = () => {
        this.setState({
            addThree:false
        })
    }
    cancelfour = () => {
        this.setState({
            addfour:false
        })
    }
    cancelfive = () => {
        this.setState({
            addfive:false
        })
    }
    render() {
        const { one, two, three, four, five, oneIndex, twoIndex, threeIndex, fourIndex, fiveIndex, addTwo, addThree, addfive, addfour } = this.state
        return (
            <div>
                <div className="search flex_between">
                    <Col span={12}>
                        <div className="selectTitle flex_start">
                            {
                                one.map((item, index)=>{
                                    return (
                                        <div className={`list ${oneIndex === index ? 'active' :'' }`} key={item.id} onClick={this.selectOne.bind(this,item,index)}>
                                            {item.category_name}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Col>
                    <div className="flex_start switchDiv" onClick={this.switchShow.bind(this,'2')}>
                        <img className="switchImg" alt="" src={require("../../static/icon/goods/change.png.svg")} />
                        <div>切换显示方式</div>
                    </div>
                </div>
                <div className="categoryTop flex_start">
                    <div>一级分类</div>
                    <div>二级分类</div>
                    <div>三级分类</div>
                    <div>四级分类</div>
                </div>
                <div className="uls">
                    <ul>
                        
                        {
                            two.map((item,index) => {
                                return (
                                    <li className={`flex_between ${twoIndex === index ? 'active' :'' }`} key={item.id} onClick={this.selectTwo.bind(this,item,index)}>
                                        <span>{item.category_name}</span>
                                        {
                                            twoIndex === index ? (
                                                <span onClick={this.deletes.bind(this,item.id,item.pid,2)} className="deletes">删除</span>
                                            ) : (null)
                                        }
                                    </li>
                                )
                            })
                        }
                        {
                            addTwo ? (
                                <li className="saveOrCancel">
                                    <Input size="large" onChange={this.addChange2} />
                                    <span className="save" onClick={this.addShopCategory2}>保存</span>
                                    <span className="cancelBtn" onClick={this.cancelTwo}>取消</span>
                                </li>
                            ) : (null)
                        }
                        {
                            !addTwo ? (
                                <li className="add" onClick={this.openAdd2}>新 增</li>
                            ) : (null)
                        }
                    </ul>
                    <ul>
                        {
                            three.map((item,index) => {
                                return(
                                    <li className={`flex_between ${threeIndex === index ? 'active' :'' }`} key={item.id} onClick={this.selectThree.bind(this,item,index)}>
                                        <span>{item.category_name}</span>
                                        {
                                            threeIndex === index ? (
                                                <span onClick={this.deletes.bind(this,item.id,item.pid,3)} className="deletes">删除</span>
                                            ) : (null)
                                        }
                                    </li>
                                )
                            })
                        }
                        {
                            addThree ? (
                                <li className="saveOrCancel" v-if="addThree">
                                    <Input size="large" onChange={this.addChange3} />
                                    <span className="save" onClick={this.addShopCategory3}>保存</span>
                                    <span className="cancelBtn" onClick={this.cancelThree}>取消</span>
                                </li>
                            ) : (null)
                        }
                        {
                            !addThree ? (
                                <li className="add" onClick={this.openAdd3}>新 增</li>
                            ) : (null)
                        }
                    </ul>
                    <ul>
                        {
                            four.map((item,index) => {
                                return (
                                    <li className={`flex_between ${fourIndex === index ? 'active' :'' }`} key={item.id} onClick={this.selectFour.bind(this,item,index)}>
                                        <span>{item.category_name}</span>
                                        {
                                            fourIndex === index ? (
                                                <span onClick={this.deletes.bind(this,item.id,item.pid,4)} className="deletes">删除</span>
                                            ) : (null)
                                        }
                                    </li>
                                )
                            })
                        }
                        {
                            addfour ? (
                                <li className="saveOrCancel" v-if="addfour">
                                    <Input size="large" onChange={this.addChange4} />
                                    <span className="save" onClick={this.addShopCategory4}>保存</span>
                                    <span className="cancelBtn" onClick={this.cancelfour}>取消</span>
                                </li>
                            ) : (null)
                        }
                        {
                            !addfour ? (
                                <li className="add" onClick={this.openAdd4}>新 增</li>
                            ) : (null)
                        }
                    </ul>
                    <ul>
                        {
                            five.map((item,index) => {
                                return(
                                    <li className={`flex_between ${fiveIndex === index ? 'active' :'' }`} key={item.id} onClick={this.selectfive.bind(this,item,index)}>
                                        <span>{item.category_name}</span>
                                        {
                                            fiveIndex === index ? (
                                                <span onClick={this.deletes.bind(this,item.id,item.pid,5)} className="deletes">删除</span>
                                            ) : (null)
                                        }
                                    </li>
                                )
                            })
                        }
                        {
                            addfive ? (
                                <li className="saveOrCancel">
                                    <Input size="large" onChange={this.addChange5} />
                                    <span className="save" onClick={this.addShopCategory5}>保存</span>
                                    <span className="cancelBtn" onClick={this.cancelfive}>取消</span>
                                </li>
                            ) : (null)
                        }
                        {
                            !addfive ? (
                                <li className="add" onClick={this.openAdd5}>新 增</li>
                            ) : (null)
                        }
                    </ul>
                </div>
            </div>
        )
    }
}
