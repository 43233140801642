import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { message, Row, Col, Table, Switch, Pagination, Button } from 'antd'
import { DelBtn } from '../index'
import { getShowList, changeShowStatus, delShopCategory } from '../../util/stan'
import { post, hasPremission } from '../../util'
class TypeTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchFrom:{
                pid:'',
                page: 1,
                per_page: 20,
            },
            tableData:[],
            total:500
        }
    }
    componentWillMount(){
        var postData = sessionStorage.getItem("postDataOne");
        if (postData) {
            var data = JSON.parse(postData);
            console.log(data)
            this.setState({
                searchFrom: data
            })
        }
        sessionStorage.removeItem("postDataOne")
    }
    componentDidMount(){
        this.ajax()
    }
    ajax(){
        post(getShowList, this.state.searchFrom).then(res=>{
            if (res.status_code === 0) {
                this.setState({
                    tableData: res.data.data,
                    total: res.data.total
                })
            } else {
                message.error(res.message)
            }
        })
    }
    switchShow = (showType) => {
        this.props.changeType(showType)
        this.ajax()
    }
    goNext = (record) => {
        sessionStorage.setItem("postDataOne",JSON.stringify(this.state.searchFrom))
        this.props.history.push('/admin/commodityManagement/classifyTwo?id=' + record.id)
    }
    switchChange = (record) => {
        post(changeShowStatus, {id: record.id}).then(res=>{
            if (res.status_code === 0) {
                message.success('操作成功')
                this.ajax()
            } else {
                message.error(res.message)
            }
        })
    }
    specification = (record) => {
        sessionStorage.setItem("postDataOne",JSON.stringify(this.state.searchFrom))
        this.props.history.push('/admin/commodityManagement/classify/spec?id=' + record.id + '&name=' + record.category_name)
    }
    property = (record) => {
        sessionStorage.setItem("postDataOne",JSON.stringify(this.state.searchFrom))
        this.props.history.push('/admin/commodityManagement/classify/property?id=' + record.id + '&name=' + record.category_name)
    }
    delete = (id) => {
        post(delShopCategory, {id: id}).then(res=>{
            if (res.status_code === 0) {
                message.success('操作成功')
                this.ajax()
            } else {
                message.error(res.message)
            }
        })
    }
    //每页显示数量改变
    onShowSizeChange = (current, pageSize) => {
        let searchFrom = Object.assign({}, this.state.searchFrom, {per_page:pageSize})
        this.setState({
            searchFrom
        },() => {
            this.ajax()
        })
    }
    //跳转页
    onPageChange = (current, pageSize) => {
        let searchFrom = Object.assign({}, this.state.searchFrom, {page:current})
        this.setState({
            searchFrom
        },() => {
            this.ajax()
        })
    }
    render() {
        const { tableData, total, searchFrom } = this.state;
        const columns = [{
            title: '类型',
            dataIndex: 'type_name',
            key:'type_name',
            align:'center',
        },{
            title: '分类名称',
            dataIndex: 'category_name',
            key:'category_name',
            align:'center',
            render: (text, record) => (
                <Button type="link" size='small' onClick={this.goNext.bind(this,record)}>{record.category_name}</Button>
            ),
        },{
            title: '级别',
            dataIndex: 'type_name',
            key:'type_name',
            align:'center',
            render: (text, record) => (
                <div>一级</div>
            ),
        },{
            title: '商品数量（件）',
            dataIndex: 'goods_num',
            key:'goods_num',
            align:'center'
        },{
            title: '规格数量',
            dataIndex: 'standard_count',
            key:'standard_count',
            align:'center'
        },{
            title: '属性数量',
            dataIndex: 'attribute_count',
            key:'attribute_count',
            align:'center'
        },{
            title: '是否显示',
            dataIndex: 'is_show',
            key:'is_show',
            align:'center',
            render: (text, record) => (
                <Switch disabled={!hasPremission('146')} checked={record.is_show === 1 ? true : false} onChange={this.switchChange.bind(this,record)}/>
            ),
        },{
            title: '管理',
            dataIndex: 'id',
            key:'id',
            align:'center',
            render: (text, record) => (
                <div className="flex_center">
                    {
                        hasPremission('145') ? (
                            <Button type="link" size='small' style={{marginRight:20}} onClick={this.specification.bind(this,record)}>规格管理</Button>
                        ) : (null)
                    }
                    {
                        hasPremission('144') ? (
                            <Button type="link" size='small' onClick={this.property.bind(this,record)}>属性管理</Button>
                        ) : (null)
                    }
                </div>
            ),
        },{
            title: '操作',
            dataIndex: 'id',
            key:'id',
            align:'center',
            render: (text, record) => (
                <div>
                    {
                        hasPremission('155') ? (
                            <DelBtn toDO={this.delete} id={record.id} />
                        ) : (null)
                    }
                </div>
            ),
        }]
        return (
            <div className="tabContainer">
                <div className="search flex_between">
                    <div className="twoTypeTitle">分类管理</div>
                    <div className="flex_start switchDiv" onClick={this.switchShow.bind(this,'1')}>
                        <img className="switchImg" alt="" src={require("../../static/icon/goods/change.png.svg")} />
                        <div>切换显示方式</div>
                    </div>
                </div>
                <div className="tabCon">
                    <Table bordered rowKey={record => record.id} onChange={this.onChange} pagination={false} columns={columns} dataSource={tableData} />
                    <Row className="pageCon">
                        <Col span={24} className="right">
                            <Pagination pageSizeOptions={['10','20','30','40']} total={total} showTotal={total => `共 ${total} 条`} showSizeChanger current={searchFrom.page} pageSize={searchFrom.per_page}
                            onShowSizeChange={this.onShowSizeChange} onChange={this.onPageChange} defaultCurrent={searchFrom.page} defaultPageSize={searchFrom.per_page}/>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
export default withRouter(TypeTable)