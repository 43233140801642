import Config from "./config";
//首页
const editPassword = Config.apiUrl + "platform/admin/editPassword"; //修改管理员密码
//用户管理
const platformUserList = Config.apiUrl + "platform/user/list"; //用户列表
const userChangeStatus = Config.apiUrl + "platform/user/changeStatus"; //修改用户状态
const platformUserInfo = Config.apiUrl + "platform/user/info"; //用户详情
const userOrderList = Config.apiUrl + "platform/user/orderList"; //用户详情订单列表
const userDelete = Config.apiUrl + "platform/user/delete"; //用户列表删除
//订单管理
const orderList = Config.apiUrl + "platform/order/list"; //订单管理 订单列表
const orderInfo = Config.apiUrl + "platform/order/info"; //订单详情
const orderStatus = Config.apiUrl + "common/common/orderStatus"; //获取所有的订单状态
const receiveQrOrderList = Config.apiUrl + "platform/receiveQrOrder/list"; //收钱码订单列表
const receiveQrOrderStatus =
  Config.apiUrl + "platform/receiveQrOrder/getStatus"; //收钱码订单列表->订单状态
const receiveQrOrderDel = Config.apiUrl + "platform/receiveQrOrder/delete"; //收款码订单列表->删除
const receiveQrOrderInfo = Config.apiUrl + "platform/receiveQrOrder/info"; //收款码订单列表->详情
const receiveQrOrderDelivery =
  Config.apiUrl + "platform/receiveQrOrder/delivery"; //收款码订单列表->关联物流单号
const sellerServiceOrderList =
  Config.apiUrl + "platform/sellerServiceOrder/list"; //订单管理 服务码订单列表
const sellerServiceOrderInfo =
  Config.apiUrl + "platform/sellerServiceOrder/info"; //订单管理 服务码订单详情
//财务管理
const financeInfo = Config.apiUrl + "platform/finance/info"; //财务管理 账户信息
const financeServiceList = Config.apiUrl + "platform/finance/serviceList"; //财务管理 资金流水 技术服务费账户
const financeOperationList = Config.apiUrl + "platform/finance/operationList"; //财务管理 资金流水 运营账户
const financeBailCashList = Config.apiUrl + "platform/finance/bailCashList"; //财务管理 资金流水 保证金流水
const financeOperationInfo = Config.apiUrl + "platform/finance/operationInfo"; //财务管理 资金流水 技术服务费账户
const getTransactionAccount =
  Config.apiUrl + "platform/finance/getTransactionAccount"; // 转入/转出->获取入款账户/转账账户
const transactionIn = Config.apiUrl + "platform/finance/transactionIn"; // 财务管理->转入
const transactionList = Config.apiUrl + "platform/finance/transactionList"; //财务管理 转入转出记录
const getConfig = Config.apiUrl + "platform/config/getConfig"; //平台设置 基础设置 获取配置信息
const editConfig = Config.apiUrl + "platform/config/editConfig"; //平台设置 基础设置 编辑配置信息
const reserveList = Config.apiUrl + "platform/finance/reserveList"; //财务管理 备付金列表
const platIncomeList = Config.apiUrl + "platform/finance/platIncomeList"; //财务管理 平台营收列表
const aisleList = Config.apiUrl + "platform/finance/aisleList"; //财务管理 通道费用列表
const businessList = Config.apiUrl + "platform/finance/businessList"; //财务管理 自营收入列表
//部门管理
const departmentList = Config.apiUrl + "platform/department/departmentList"; //部门管理 部门列表
const getArea = Config.apiUrl + "common/common/getArea"; //省市区街道4级联动
const addDepartment = Config.apiUrl + "platform/department/addDepartment"; //部门管理 部门列表 添加部门
const editDepartment = Config.apiUrl + "platform/department/editDepartment"; //部门管理 部门列表 编辑部门
const operateDepartment =
  Config.apiUrl + "platform/department/operateDepartment"; //部门管理 部门列表 禁用与启用
const delDepartment = Config.apiUrl + "platform/department/delDepartment"; //部门管理 部门列表 删除部门
const getPerformanceInfo =
  Config.apiUrl + "platform/department/getPerformanceInfo"; //部门管理 部门列表 成员列表 绩效管理
const editBranchPerformance =
  Config.apiUrl + "platform/department/editBranchPerformance"; //部门管理 部门列表 编辑绩效
const groupAllRole = Config.apiUrl + "platform/department/groupAllRole"; //部门管理 部门列表 权限设置获取所有的权限
const settingBranchRole =
  Config.apiUrl + "platform/department/settingBranchRole"; //部门管理 部门列表 设置部门的权限
const memberList = Config.apiUrl + "platform/department/memberList"; //部门管理 成员列表
const getDepartmentAll = Config.apiUrl + "platform/department/getDepartmentAll"; //部门管理 获取所有的部门
const operateMember = Config.apiUrl + "platform/department/operateMember"; //部门管理 成员管理 启用禁用成员
const delMember = Config.apiUrl + "platform/department/delMember"; //部门管理 成员管理 删除成员
const editMemberPerformance =
  Config.apiUrl + "platform/department/editMemberPerformance"; //部门管理 成员管理 编辑成员绩效
const groupUserAllRole = Config.apiUrl + "platform/department/groupUserAllRole"; //部门管理 部门列表 权限设置获取所有的权限
const settingUserRole = Config.apiUrl + "platform/department/settingUserRole"; //部门管理 成员管理 获取成员权限
const addMember = Config.apiUrl + "platform/department/addMember"; //部门管理 成员管理 添加成员
const editMember = Config.apiUrl + "platform/department/editMember"; //部门管理 成员管理 编辑成员
const amountRange = Config.apiUrl + "platform/department/amountRange"; //部门管理 绩效管理 绩效列表
const performanceList = Config.apiUrl + "platform/department/performanceList"; //部门管理 绩效管理 绩效列表
const getAllMember = Config.apiUrl + "platform/department/getAllMember"; //部门管理 绩效管理 添加成员绩效 获取所有的成员
const addMemberPerformance =
  Config.apiUrl + "platform/department/addMemberPerformance"; //部门管理 绩效管理 添加成员绩效
const getAllBranch = Config.apiUrl + "platform/department/getAllBranch"; //部门管理 绩效管理 添加成员绩效 获取所有的部门
const addBranchPerformance =
  Config.apiUrl + "platform/department/addBranchPerformance"; //部门管理 绩效管理 添加部门绩效
const editAssess = Config.apiUrl + "platform/department/editAssess"; //部门管理 绩效管理 编辑成员 部门绩效
const delAssess = Config.apiUrl + "platform/department/delAssess"; //部门管理 绩效管理 删除单条的绩效
const bonusSettlement = Config.apiUrl + "platform/department/bonusSettlement"; //部门管理 绩效管理 奖金结算
//营销管理
const advList = Config.apiUrl + "platform/adv/advList"; //首部轮播->轮播图列表
const advOperateStatus = Config.apiUrl + "platform/adv/operateStatus"; //首部轮播->轮播图 是否显示
const delAdv = Config.apiUrl + "platform/adv/delAdv"; //首部轮播->轮播图 删除
const addAdv = Config.apiUrl + "platform/adv/addAdv"; //首部轮播->轮播图 新增
const editAdv = Config.apiUrl + "platform/adv/editAdv"; //首部轮播->轮播图 编辑
const navigateList = Config.apiUrl + "platform/navigate/navigateList"; //类目导航列表
const getAllCategory = Config.apiUrl + "platform/category/getAllCategory"; //获取全部商品品类
const editNavigate = Config.apiUrl + "platform/navigate/editNavigate"; //类目导航 编辑
const delNavigate = Config.apiUrl + "platform/navigate/delNavigate"; //类目导航 删除
const addNavigate = Config.apiUrl + "platform/navigate/addNavigate"; //类目导航 新增
//内容管理
const contentList = Config.apiUrl + "platform/content/contentList"; //帮助中心 列表
const editContent = Config.apiUrl + "platform/content/editContent"; //帮助中心 编辑帮助
const delContent = Config.apiUrl + "platform/content/delContent"; //帮助中心 删除帮助
const addContent = Config.apiUrl + "platform/content/addContent"; //帮助中心 添加帮助
//商品管理
const getCategoryList = Config.apiUrl + "common/common/getCategoryList"; //获取商品分类
const addShopCategory = Config.apiUrl + "platform/category/addShopCategory"; //添加商品分类
const delShopCategory = Config.apiUrl + "platform/category/delShopCategory"; //删除商品分类
const getShowList = Config.apiUrl + "platform/category/getShowList"; //商品分类->分类列表
const changeShowStatus = Config.apiUrl + "platform/category/changeShowStatus"; //商品分类->改变显示状态
const standardList = Config.apiUrl + "platform/category/standardList"; //商品分类->绑定规格列表
const getStandard = Config.apiUrl + "common/common/getStandard"; //商品分类->分类绑定规格（选择弹窗数据）
const bindStandard = Config.apiUrl + "platform/category/bindStandard"; //商品分类->保存绑定规格
const deleteStandard = Config.apiUrl + "platform/category/deleteStandard"; //商品分类->删除绑定规格
const attributeList = Config.apiUrl + "platform/category/attributeList"; //商品分类->绑定属性列表
const getAttribute = Config.apiUrl + "common/common/getAttribute"; //商品分类->分类绑定属性（选择弹窗数据）
const bindAttribute = Config.apiUrl + "platform/category/bindAttribute"; //商品分类->保存绑定规格
const deleteAttribute = Config.apiUrl + "platform/category/deleteAttribute"; //商品分类->删除绑定属性
// 线下经营分类
const categoryOfflineGetShowList =
  Config.apiUrl + "platform/categoryOffline/getShowList"; //线下经营分类->分类列表
const categoryOfflineAddShopCategory =
  Config.apiUrl + "platform/categoryOffline/addShopCategory"; //线下经营分类->添加分类
const categoryOfflineDelShopCategory =
  Config.apiUrl + "platform/categoryOffline/delShopCategory"; //线下经营分类->删除分类
const categoryOfflineChangeShowStatus =
  Config.apiUrl + "platform/categoryOffline/changeShowStatus"; //线下经营分类->分类列表
const getOfflineCategoryList =
  Config.apiUrl + "common/common/getOfflineCategoryList"; //线下经营分类->分类列表

export {
  editPassword,
  platformUserList,
  userChangeStatus,
  platformUserInfo,
  userOrderList,
  userDelete,
  orderInfo,
  orderList,
  orderStatus,
  receiveQrOrderList,
  receiveQrOrderDel,
  receiveQrOrderStatus,
  receiveQrOrderInfo,
  receiveQrOrderDelivery,
  sellerServiceOrderList,
  sellerServiceOrderInfo,
  financeInfo,
  financeServiceList,
  financeOperationList,
  financeBailCashList,
  financeOperationInfo,
  getTransactionAccount,
  transactionIn,
  transactionList,
  getConfig,
  editConfig,
  reserveList,
  platIncomeList,
  aisleList,
  businessList,
  departmentList,
  getArea,
  addDepartment,
  editDepartment,
  operateDepartment,
  delDepartment,
  getPerformanceInfo,
  editBranchPerformance,
  groupAllRole,
  groupUserAllRole,
  settingBranchRole,
  memberList,
  operateMember,
  delMember,
  editMemberPerformance,
  settingUserRole,
  getDepartmentAll,
  addMember,
  editMember,
  amountRange,
  performanceList,
  getAllMember,
  addMemberPerformance,
  getAllBranch,
  addBranchPerformance,
  editAssess,
  delAssess,
  bonusSettlement,
  advList,
  advOperateStatus,
  delAdv,
  addAdv,
  editAdv,
  navigateList,
  getAllCategory,
  editNavigate,
  delNavigate,
  addNavigate,
  contentList,
  editContent,
  delContent,
  addContent,
  getCategoryList,
  addShopCategory,
  delShopCategory,
  getShowList,
  changeShowStatus,
  standardList,
  getStandard,
  bindStandard,
  deleteStandard,
  attributeList,
  getAttribute,
  bindAttribute,
  deleteAttribute,
  categoryOfflineGetShowList,
  categoryOfflineAddShopCategory,
  categoryOfflineDelShopCategory,
  categoryOfflineChangeShowStatus,
  getOfflineCategoryList,
};
