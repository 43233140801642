import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { adminRouter } from "../../routes";
const breadcrumbNameMap = {};
class NewBreadcrumb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pathSnippets: null,
      extraBreadcrumbItems: null,
    };
  }
  getPath = (path) => {
    const pathname = path ? path : this.props.history.location.pathname;
    let pathSnippets = pathname.split("/").filter((i) => i);
    let extraBreadcrumbItems = pathSnippets.map((_, index) => {
      let url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      return (
        <Breadcrumb.Item style={{ lineHeight: "50px" }} key={url}>
          {breadcrumbNameMap[url] ? (
            <Link to={url}>{breadcrumbNameMap[url]}</Link>
          ) : null}
        </Breadcrumb.Item>
      );
    });
    this.setState({
      pathSnippets: pathSnippets,
      extraBreadcrumbItems: extraBreadcrumbItems,
    });
  };
  refresh = () => {
    window.location.reload();
  };
  back = () => {
    this.props.history.goBack();
  };
  componentWillMount() {
    adminRouter.forEach((route, index) => {
      breadcrumbNameMap[route.pathname] = route.name;
    });
    this.getPath();
    this.props.history.listen((route) => {
      this.getPath(route.pathname);
    });
  }
  componentWillUnmount() {
    this.setState({});
  }
  render() {
    return (
      <div
        className="flex_between"
        style={{ background: "#fff", padding: "0 20px" }}
      >
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          {this.state.extraBreadcrumbItems}
        </Breadcrumb>
        <div className="flex_start">
          <img
            style={{
              width: "16px",
              height: "16px",
              marginRight: "30px",
              cursor: "pointer",
            }}
            alt=""
            onClick={this.back}
            src={require("../../static/icon/topBar/back.svg")}
          />
          <img
            style={{ width: "16px", height: "16px", cursor: "pointer" }}
            className="closeImg"
            alt=""
            onClick={this.refresh}
            src={require("../../static/icon/topBar/refresh.svg")}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(NewBreadcrumb);
