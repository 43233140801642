import React, { Component } from 'react'
import {Route, withRouter} from 'react-router-dom'

class PrivateRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: window.sessionStorage.getItem("token") ? true: false
        }
    }
    componentWillMount() {
        const allBtnRole = this.props.params
        const myRole = JSON.parse(window.sessionStorage.getItem('myRole'))
        const myBtn = []
        if (allBtnRole && myRole) {
            myRole.forEach(item=>{
                if(item.children) {
                    item.children.forEach(two=>{
                        if (two.id === allBtnRole.id) {
                            if (two.children) {
                                two.children.forEach(btn=>{
                                    myBtn.push(btn.id)
                                })
                            }
                        }
                    })
                }
            })
        }
        window.sessionStorage.setItem('myBtn',myBtn)
        if(!this.state.isLogin){
            const { history } = this.props;
            history.replace("/login");
        }
    }
    render() {
        let { component: Component, ...rest} = this.props;
        return  this.state.isLogin ? 
        (<Route {...rest} render={(props) => ( <Component {...props} /> 
            )}/> ) : (null)

    }
}
export default withRouter(PrivateRoute);