import Loadable from "react-loadable";
import { Loading } from "../components";
//懒加载
const Dashboard = Loadable({
  loader: () => import("./Dashboard"),
  loading: Loading,
});
const Login = Loadable({
  loader: () => import("./Login"),
  loading: Loading,
});
const NotFound = Loadable({
  loader: () => import("./NotFound"),
  loading: Loading,
});
const UserList = Loadable({
  loader: () => import("./User/userList"),
  loading: Loading,
});
const UserDetail = Loadable({
  loader: () => import("./User/userDetail"),
  loading: Loading,
});
const MerchantList = Loadable({
  loader: () => import("./businessManagement/merchantList"),
  loading: Loading,
});
const SeasMerchants = Loadable({
  loader: () => import("./businessManagement/seasMerchants"),
  loading: Loading,
});
const MerchantDetail = Loadable({
  loader: () => import("./businessManagement/merchantDetail"),
  loading: Loading,
});
const OrderList = Loadable({
  loader: () => import("./orderManagement/orderList"),
  loading: Loading,
});
const OrderDetail = Loadable({
  loader: () => import("./orderManagement/orderDetail"),
  loading: Loading,
});
const ReceivePaymentCodeOrder = Loadable({
  loader: () => import("./orderManagement/receivePaymentCodeOrder"),
  loading: Loading,
});
const CodeOrderDetail = Loadable({
  loader: () => import("./orderManagement/codeOrderDetail"),
  loading: Loading,
});
const AllianceBusinessList = Loadable({
  loader: () => import("./orderManagement/allianceBusinessList"),
  loading: Loading,
});
const AllianceBusinessDetail = Loadable({
  loader: () => import("./orderManagement/allianceBusinessDetail"),
  loading: Loading,
});
const AccountInformation = Loadable({
  loader: () => import("./financialManagement/accountInformation"),
  loading: Loading,
});
const Capital = Loadable({
  loader: () => import("./financialManagement/capital"),
  loading: Loading,
});
const ShiftTo = Loadable({
  loader: () => import("./financialManagement/shiftTo"),
  loading: Loading,
});
const ShiftOut = Loadable({
  loader: () => import("./financialManagement/shiftOut"),
  loading: Loading,
});
const TransferRecord = Loadable({
  loader: () => import("./financialManagement/transferRecord"),
  loading: Loading,
});
const BudgetControl = Loadable({
  loader: () => import("./financialManagement/budgetControl"),
  loading: Loading,
});
const Provisions = Loadable({
  loader: () => import("./financialManagement/provisions"),
  loading: Loading,
});
const PlatformRevenue = Loadable({
  loader: () => import("./financialManagement/platformRevenue"),
  loading: Loading,
});
const ChannelCost = Loadable({
  loader: () => import("./financialManagement/channelCost"),
  loading: Loading,
});
const ProprietaryIncome = Loadable({
  loader: () => import("./financialManagement/proprietaryIncome"),
  loading: Loading,
});
const Departmentlist = Loadable({
  loader: () => import("./divisionalManagement/departmentlist"),
  loading: Loading,
});
const Departmentlimit = Loadable({
  loader: () => import("./divisionalManagement/departmentlimit"),
  loading: Loading,
});
const Subdivision = Loadable({
  loader: () => import("./divisionalManagement/subdivision"),
  loading: Loading,
});
const MemberList = Loadable({
  loader: () => import("./divisionalManagement/memberList"),
  loading: Loading,
});
const Memberlimit = Loadable({
  loader: () => import("./divisionalManagement/memberlimit"),
  loading: Loading,
});
const MemberAdd = Loadable({
  loader: () => import("./divisionalManagement/memberAdd"),
  loading: Loading,
});
const MemberEdit = Loadable({
  loader: () => import("./divisionalManagement/memberEdit"),
  loading: Loading,
});
const Productivity = Loadable({
  loader: () => import("./divisionalManagement/productivity"),
  loading: Loading,
});
const BonusSettlement = Loadable({
  loader: () => import("./divisionalManagement/bonusSettlement"),
  loading: Loading,
});
const Basic = Loadable({
  loader: () => import("./platformSetting/basic"),
  loading: Loading,
});
const Captcha = Loadable({
  loader: () => import("./platformSetting/captcha"),
  loading: Loading,
});
const Area = Loadable({
  loader: () => import("./platformSetting/area"),
  loading: Loading,
});
const Message = Loadable({
  loader: () => import("./platformSetting/message"),
  loading: Loading,
});
const Partner = Loadable({
  loader: () => import("./partner"),
  loading: Loading,
});
const PartnerStep = Loadable({
  loader: () => import("./partner/create"),
  loading: Loading,
});
const PartnerStepEdit = Loadable({
  loader: () => import("./partner/layout"),
  loading: Loading,
});
const RateSet = Loadable({
  loader: () => import("./platformSetting/rateSet"),
  loading: Loading,
});
const Log = Loadable({
  loader: () => import("./platformSetting/log"),
  loading: Loading,
});
const FunctionAllocation = Loadable({
  loader: () => import("./platformSetting/functionAllocation"),
  loading: Loading,
});
const FunctionAllocation2 = Loadable({
  loader: () => import("./platformSetting/functionAllocation_2"),
  loading: Loading,
});
const FunctionAllocation3 = Loadable({
  loader: () => import("./platformSetting/functionAllocation_3"),
  loading: Loading,
});
const Marketing = Loadable({
  loader: () => import("./platformSetting/marketing"),
  loading: Loading,
});
const VersionsList = Loadable({
  loader: () => import("./platformSetting/versionsList"),
  loading: Loading,
});
const VersionsRelease = Loadable({
  loader: () => import("./platformSetting/versionsRelease"),
  loading: Loading,
});
const VersionsDetail = Loadable({
  loader: () => import("./platformSetting/versionsDetail"),
  loading: Loading,
});
const VersionsAmend = Loadable({
  loader: () => import("./platformSetting/versionsAmend"),
  loading: Loading,
});
const Aptitudes = Loadable({
  loader: () => import("./platformSetting/aptitudes"),
  loading: Loading,
});
const AptitudesDetail = Loadable({
  loader: () => import("./platformSetting/aptitudesDetail"),
  loading: Loading,
});
const HomePage = Loadable({
  loader: () => import("./marketingManagement/homePage"),
  loading: Loading,
});
const AdvAdd = Loadable({
  loader: () => import("./marketingManagement/advAdd"),
  loading: Loading,
});
const AdvEdit = Loadable({
  loader: () => import("./marketingManagement/advEdit"),
  loading: Loading,
});
const CategoryNavigation = Loadable({
  loader: () => import("./marketingManagement/categoryNavigation"),
  loading: Loading,
});
const HelpCenter = Loadable({
  loader: () => import("./contentManagement/helpCenter"),
  loading: Loading,
});
const HelpAdd = Loadable({
  loader: () => import("./contentManagement/helpAdd"),
  loading: Loading,
});
const HelpEdit = Loadable({
  loader: () => import("./contentManagement/helpEdit"),
  loading: Loading,
});
const Classify = Loadable({
  loader: () => import("./commodityManagement/classify"),
  loading: Loading,
});
const ClassifyTwo = Loadable({
  loader: () => import("./commodityManagement/classifyTwo"),
  loading: Loading,
});
const ClassifyThree = Loadable({
  loader: () => import("./commodityManagement/classifyThree"),
  loading: Loading,
});
const ClassifyFour = Loadable({
  loader: () => import("./commodityManagement/ClassifyFour"),
  loading: Loading,
});
const classSpecifications = Loadable({
  loader: () => import("./commodityManagement/classSpecifications"),
  loading: Loading,
});
const classifyProperty = Loadable({
  loader: () => import("./commodityManagement/classifyProperty"),
  loading: Loading,
});
const Specification = Loadable({
  loader: () => import("./commodityManagement/specification"),
  loading: Loading,
});
const SpecificationAdd = Loadable({
  loader: () => import("./commodityManagement/specificationAdd"),
  loading: Loading,
});
const SpecificationAmend = Loadable({
  loader: () => import("./commodityManagement/specificationAmend"),
  loading: Loading,
});
const Property = Loadable({
  loader: () => import("./commodityManagement/property"),
  loading: Loading,
});
const PropertyAdd = Loadable({
  loader: () => import("./commodityManagement/propertyAdd"),
  loading: Loading,
});
const PropertyAmend = Loadable({
  loader: () => import("./commodityManagement/propertyAmend"),
  loading: Loading,
});
const NewColor = Loadable({
  loader: () => import("./commodityManagement/newColor"),
  loading: Loading,
});
const NewSize = Loadable({
  loader: () => import("./commodityManagement/newSize"),
  loading: Loading,
});
const GoodsRecord = Loadable({
  loader: () => import("./commodityManagement/goodsRecord"),
  loading: Loading,
});
const GoodsRecordEdit = Loadable({
  loader: () => import("./commodityManagement/goodsRecord/edit"),
  loading: Loading,
});
const ExplosiveGoodsSolution = Loadable({
  loader: () => import("./marketingManagement/explosiveGoodsSolution"),
  loading: Loading,
});
const PromotionActivity = Loadable({
  loader: () => import("./marketingManagement/promotionActivity"),
  loading: Loading,
});
const PromotionGoods = Loadable({
  loader: () => import("./marketingManagement/promotionGoods"),
  loading: Loading,
});
const SelectGoods = Loadable({
  loader: () => import("./marketingManagement/promotionGoods/selectGoods"),
  loading: Loading,
});

const LinkCity = Loadable({
  loader: () => import("./marketingManagement/linkCity"),
  loading: Loading,
});

const ExplosiveGoodsSolutionEdit = Loadable({
  loader: () => import("./marketingManagement/explosiveGoodsSolution/edit"),
  loading: Loading,
});
const advertisingPositionList = Loadable({
  loader: () => import("./marketingManagement/advertisingPosition"),
  loading: Loading,
});
const advertisingPositionInfo = Loadable({
  loader: () => import("./marketingManagement/advertisingPosition/info"),
  loading: Loading,
});
const categoryNavigationNew = Loadable({
  loader: () => import("./marketingManagement/categoryNavigationNew"),
  loading: Loading,
});
const CategoryOffline = Loadable({
  loader: () => import("./commodityManagement/categoryOffline/categoryOffline"),
  loading: Loading,
});
const CategoryOfflineTwo = Loadable({
  loader: () =>
    import("./commodityManagement/categoryOffline/categoryOfflineTwo"),
  loading: Loading,
});
const CategoryOfflineThree = Loadable({
  loader: () =>
    import("./commodityManagement/categoryOffline/categoryOfflineThree"),
  loading: Loading,
});
const CategoryOfflineFour = Loadable({
  loader: () =>
    import("./commodityManagement/categoryOffline/categoryOfflineFour"),
  loading: Loading,
});
const CategoryOfflineProperty = Loadable({
  loader: () =>
    import("./commodityManagement/categoryOffline/categoryOfflineProperty"),
  loading: Loading,
});
export {
  Dashboard,
  Login,
  NotFound,
  UserList,
  UserDetail,
  SeasMerchants,
  MerchantList,
  MerchantDetail,
  OrderList,
  OrderDetail,
  ReceivePaymentCodeOrder,
  CodeOrderDetail,
  AllianceBusinessList,
  AccountInformation,
  Capital,
  ShiftTo,
  ShiftOut,
  TransferRecord,
  BudgetControl,
  Provisions,
  PlatformRevenue,
  ChannelCost,
  ProprietaryIncome,
  Departmentlist,
  MemberList,
  Productivity,
  BonusSettlement,
  Basic,
  Captcha,
  Area,
  Message,
  RateSet,
  Log,
  FunctionAllocation,
  FunctionAllocation2,
  FunctionAllocation3,
  Marketing,
  VersionsList,
  VersionsRelease,
  VersionsDetail,
  VersionsAmend,
  Aptitudes,
  AptitudesDetail,
  HomePage,
  CategoryNavigation,
  HelpCenter,
  Classify,
  Specification,
  SpecificationAdd,
  Property,
  AllianceBusinessDetail,
  Departmentlimit,
  Subdivision,
  Memberlimit,
  MemberAdd,
  MemberEdit,
  AdvAdd,
  AdvEdit,
  HelpAdd,
  HelpEdit,
  classSpecifications,
  classifyProperty,
  ClassifyTwo,
  ClassifyThree,
  ClassifyFour,
  SpecificationAmend,
  PropertyAdd,
  PropertyAmend,
  NewColor,
  NewSize,
  Partner,
  PartnerStep,
  PartnerStepEdit,
  GoodsRecord,
  GoodsRecordEdit,
  ExplosiveGoodsSolution,
  ExplosiveGoodsSolutionEdit,
  PromotionActivity,
  PromotionGoods,
  LinkCity,
  SelectGoods,
  advertisingPositionList,
  advertisingPositionInfo,
  categoryNavigationNew,
  CategoryOffline,
  CategoryOfflineTwo,
  CategoryOfflineThree,
  CategoryOfflineFour,
  CategoryOfflineProperty,
};
