import React from 'react'
import { Switch } from 'react-router-dom'
import { adminRouter } from './routes'
import { Frame } from './components'
import PrivateRoute from "./privateRoute";
function App() {
    return (
        <Frame>
            <Switch>
                {
                    adminRouter.map((route => {
                        return <PrivateRoute params={route.params} exact={route.exact} key={route.pathname} path={route.pathname} component={route.component} />
                    }))
                }
            </Switch>
        </Frame>
    )
}
export default App
