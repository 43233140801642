import React, { Component } from 'react'
import { Table } from 'antd'

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: props.defaultCol,
            tableData:props.tableData
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.columns.length) {
            this.setState({
                columns: nextProps.columns, 
                tableData: nextProps.tableData
            });
        } else {
            this.setState({
                tableData: nextProps.tableData
            });
        }
    }
    render() {
        const { tableData, columns } = this.state
        return (
            <div>
                <div className="tabCon">
                    <Table bordered rowKey={record => record.id} pagination={false} columns={columns} dataSource={tableData} />
                    
                </div>
            </div>
        )
    }
}
