import React, { Component } from 'react'
import { Button, Modal } from 'antd'

export default class DelBtn extends Component {
    constructor(props){
        super(props)
        this.state={
            visible: false,
        }
    }
    del = () => {
        this.setState({
            visible:true
        })
    }
    handleOk = () => {
        this.props.toDO(this.props.id);
        this.setState({
            visible:false
        })
    }
    handleCancel = () => {
        this.setState({
            visible:false
        })
    }
    render() {
        return (
            <span>
                <Button type="link" danger onClick={this.del}>{
                    this.props.title?this.props.title:'删除'
                }</Button>
                <Modal title="提示" centered visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel}>
                    <p>
                        {
                            this.props.text?this.props.text:'确定删除?'
                        }</p>
                </Modal>
            </span>
        )
    }
}
