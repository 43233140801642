import axios from "axios";

function post(url, data = {}) {
  let postData = data;
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: url,
      data: postData,
    }).then((response) => {
      resolve(response.data);
    });
    // axios.post(url,postData).then((res)=>{
    // 	resolve(res)
    // })
  });
}
function objDeepCopy(source) {
  var sourceCopy = {};
  for (var item in source)
    sourceCopy[item] =
      typeof source[item] === "object"
        ? objDeepCopy(source[item])
        : source[item];
  return sourceCopy;
}
function hasPremission(id) {
  const myPremission = sessionStorage.getItem("myBtn");
  return myPremission.indexOf(id) !== -1;
}
function coppyArray(arr) {
  return arr.map((e) => {
    if (typeof e === "object") {
      return Object.assign({}, e);
    } else {
      return e;
    }
  });
}
function add0(m) {
  return m < 10 ? "0" + m : m;
}
function formatTimestamp(sj) {
  var now = new Date(sj * 1000);
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var date = now.getDate();
  var hour = now.getHours();
  var minute = now.getMinutes();
  var second = now.getSeconds();
  return (
    year +
    "-" +
    add0(month) +
    "-" +
    add0(date) +
    "   " +
    add0(hour) +
    ":" +
    add0(minute) +
    ":" +
    add0(second)
  );
}
function formatMonth(sj) {
  var now = new Date(sj * 1000);
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  return year + "-" + add0(month);
}
function formatTimes(sj) {
  var now = new Date(sj * 1000);
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var date = now.getDate();
  return year + "-" + add0(month) + "-" + add0(date);
}
function getUrlKey(name, str) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  const r = str.substr(1).match(reg);
  if (r != null) return decodeURIComponent(r[2]);
  return null;
}

// 解析路由参数
const paserQuery = (url) => {
  const index = url.indexOf("?");
  if (index === -1) return {};
  const tempQuery = url.split("?")[1];
  const questList = tempQuery.split("&");
  const queryData = {};
  questList.forEach((item) => {
    const tempList = item.split("=");
    if (tempList[0]) {
      // eslint-disable-next-line prefer-destructuring
      queryData[tempList[0]] = tempList[1];
    }
  });
  return queryData;
};
// 添加路由拦截
axios.interceptors.request.use(
  (config) => {
    var token = sessionStorage.getItem("token");
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
//添加响应拦截器
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      // router.push({
      // 	path: "/login",
      // });
    }
    return Promise.reject(error);
  }
);
export {
  post,
  getUrlKey,
  objDeepCopy,
  formatTimestamp,
  formatTimes,
  coppyArray,
  formatMonth,
  hasPremission,
  paserQuery,
};
